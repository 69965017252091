import React from "react";
import { useSelector } from "react-redux";
import {
  CustomerListProvider,
  CustomerSearch,
  CustomerSearchNew,
} from "@src/components";
import { FFlags, getFlag } from "@src/enums";
import { flagsSelector } from "@src/store/selector";

export interface CustomerSearchPageProps {}

export default (props: CustomerSearchPageProps) => {
  const flags = useSelector(flagsSelector);

  return (
    flags?.[getFlag(FFlags.NewTenantsPage)] ? (
      <CustomerSearchNew />
    ) : (
      <CustomerListProvider>
        <CustomerSearch />
      </CustomerListProvider>
    )
  );
};
