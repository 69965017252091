import { IDataTableColumn, ITenant } from "@src/interfaces";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { LoadingSpinner, Page } from "..";
import { DataTable, SubmitButton, DropDownMenu } from "@src/components";
import { Button, TextField } from "@emburse/embark-core";
import { v4 } from "uuid";
import { IDropDownMenuItem } from "../../interfaces/IDropDownMenuItem";
import { GridSortModel, IUpdatedDetails } from '@emburse/embark-core';

export interface CustomerSearchProps {}

const useStyles = createUseStyles({
  manageCustomerBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",

    "& > span:last-child": {
      marginLeft: "auto",
    },
    "& > :first-child": {
      marginRight: "15px",
    },
  },
  searchButton: {
    marginLeft: "15px",
  },
  clearButton: {
    marginLeft: "15px",
  },
});

export default (props: CustomerSearchProps) => {
  const styles = useStyles();

  const [searchField, setSearchField] = useState("");

  const [tenants, setTenants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [buFilter, setBuFilter] = useState("CR");
  const [loading, setLoading] = useState(false);

  const fetchTenants = async () => {
    setLoading(true);
    const baseUrl = "/app/admin/tenants";
    const fields = "tenant_id,name,business_unit,bu_env,bu_shard,bu_unique_id";
    const fullUrl = `${baseUrl}?fields=${fields}&business_unit=${buFilter}&search=${searchTerm}&fetch_all=False&page=${1}&per_page=${25}`;
    const res = await fetch(fullUrl);
    const body = await res.json();
    setTenants(body.tenants);
    setLoading(false);
  };

  useEffect(() => {
    fetchTenants();
  }, [buFilter, searchTerm]);

  let menuList: IDropDownMenuItem[] = [
    {
      label: "Chrome River",
      value: "CR",
      key: "CR",
    },
    {
      label: "Certify",
      value: "CE",
      key: "CE",
    },
    {
      label: "Spend",
      value: "Spend",
      key: "AB",
    },
    {
      label: "DVI",
      value: "DVI",
      key: "DVI",
    },
    {
      label: "All Business Units",
      value: "ALL",
      key: "ALL",
    },
  ];

  function generateCustomerName(tenant: ITenant) {
    /**
     * Include the customer ID in tenant name for CR business unit
     */
    if (tenant.business_unit === 'cr') {
      if (tenant.bu_unique_id !== null) {
        return `${tenant.name} (${tenant.bu_unique_id})`
      }
    }
    return tenant.name
  }

  const mapTenantsToDataTable = (tenants: ITenant[]) => {
    return tenants.map((tenant) => ({
      customerName: generateCustomerName(tenant),
      id: v4(),
      tenant_id: tenant.tenant_id,
      businessUnit: tenant.business_unit.toUpperCase(),
      businessUnitEnv: tenant.bu_env.toUpperCase(),
      businessUnitShard: tenant.bu_shard.toUpperCase(),
      // permissions: tenant.analytics_role
    }));
  };

  let handleDropDownSelect = (item: IDropDownMenuItem) => {
    setBuFilter(item.value);
  };

  let columns: IDataTableColumn[] = [
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
    },
    {
      field: "businessUnit",
      headerName: "Business Unit",
      flex: 1,
    },
    {
      field: "businessUnitEnv",
      headerName: "Business Unit Environment",
      flex: 1,
    },
    {
      field: "businessUnitShard",
      headerName: "Business Unit Shard",
      flex: 1,
    },
    {
      field: "review",
      headerName: "Review",
      renderCell: (val) => (
        <SubmitButton
          action={`/app/admin/tenants/${val.row.tenant_id}/review/login`}
          method="post"
          variant="outlined"
          color="secondary"
          data-qa={`reviewlogin-${val.row.tenant_id}`}
          data-tag="reviewlogin"
        >
          Review
        </SubmitButton>
      ),
      width: 175,
    },
  ];

  return (
    <Page>
      <h2>Customers</h2>

      <div className={styles.manageCustomerBar}>
        <span>
          <TextField
            id="customerSearch"
            placeholder="Search"
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
          <span className={styles.searchButton}>
            <Button
              data-qa="searchButton"
              onClick={(e) => {
                setSearchTerm(searchField);
              }}
            >
              Search
            </Button>
          </span>
          <span className={styles.clearButton}>
            <Button
              onClick={(e) => {
                setSearchField("");
                setSearchTerm("");
              }}
            >
              Clear
            </Button>
          </span>
        </span>
        <DropDownMenu
          menuList={menuList}
          onSelect={handleDropDownSelect}
        ></DropDownMenu>
      </div>

      {!loading && tenants.length > 0 && (
        <DataTable
          autoHeight
          hideFooterPagination
          server
          rows={mapTenantsToDataTable(tenants)}
          rowCount={999}
          columns={columns} />
      )}
      {loading && <LoadingSpinner />}
    </Page>
  );
};

// this is for logging. Linter formatted this file.
