import './UTPModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import EmptyFolder from '../Icons/EmptyFolder';
import { hideModal } from '../../store/actions';
import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    Link } from '@emburse/embark-core';
import { FFlags, getFlag } from "@enums";
import {flagsSelector} from "@src/store/selector";

export default ({modal}) => {
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(hideModal())
    };

    const flags = useSelector(flagsSelector);
    const useNewText = flags?.[getFlag(FFlags.UseNewUpgradeText)];
    const texts = {
        header: useNewText ? "Upgrade Emburse Analytics" : "Upgrade To Analytics Pro",
        link: useNewText ? "Emburse Analytics" : "Emburse Analytics Pro",
        prefix: useNewText ? "Upgrade" : "Upgrade to"
    };

    const modalHeader = (<ModalHeader title={texts.header} showClose={true} onCloseClicked={closeModal}/>);

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button variant="contained" disabled={modal.loading} onClick={closeModal}>
                    Close 
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <EmptyFolder />
                <div>
                    <p>{texts.prefix} <Link href="https://www.emburse.com/solutions/analytics" target="_blank">{texts.link}</Link> to:</p> 
                    <ul>
                        <li>build your own dashboards</li>
                        <li>reference custom fields in your dashboards and reports</li>
                        <li>additional sharing capabilities</li>
                        <li>schedule report distribution</li>
                        <li>grant access to all your people managers</li>
                    </ul>    
                    <p>Contact your Customer Success Manager to learn more!</p>
                </div>
            </div>
        </Modal>
    )
}